.paperContainer {
  background-color: #eee;
  padding: 20px 20px;
  border-radius: 6px;
  min-width: 794px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 794px;
  min-height: 1057px;
  padding: 50px 45px 30px 45px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 10px 10px rgba(0,0,0, .1);
  position: relative;
}

.footer {
  border-top: 1px solid #F2F2F2;
  margin-top: 20px;
  padding-top: 20px;
  font-size: .8em;
}
