$transition: all 150ms ease-in-out;

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #C0CADD;
  font-size: 14px;
  font-weight: 500;

  padding: 5px 15px;

  transition: $transition;

  cursor: pointer;

  &:hover, &.selected {
    background-color: #E9F1FF;
    font-weight: 600;
    color: #216DF8;
  }
}
