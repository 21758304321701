.formContainer {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;

  transition: all 300ms ease-in-out;
}

.form {
  width: 100%;
  height: fit-content;
  position: absolute;
  transition: all 300ms ease-in-out;

  transform: translateX(100px);
  opacity: 0;
  pointer-events: none;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
}

.link {
  cursor: pointer;
  padding-top: 10px;
  color: #9AAAC9;

  > a {
    color: #9AAAC9;
    text-decoration: underline;
  }
}
