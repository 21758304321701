.container {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 1024px;
}

.menuContainer {
  flex-shrink: 0;
}

.contentContainer {
  max-height: 100vh;
  height: 100vh;
  flex: 1;
}

.contentInnerContainer {
  display: flex;
  z-index: 0;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: clamp(10px, 2vh, 30px) clamp(10px, 2vw, 50px) 200px clamp(10px, 2vw, 50px);
}
