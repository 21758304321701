$text-light: rgba(0,0,0,0.6);
$text-default: rgba(0,0,0,0.8);

$brand-blue: #0D60F8;
$action-blue: #06245B;
$green: #00F700;
$red: #F74425;
$yellow: #F7DB00;
$orange: #F7AD0C;
$grey: #02112F;

$blue100: $brand-blue;
$blue90: rgba($brand-blue, .90);
$blue80: rgba($brand-blue, .80);
$blue70: rgba($brand-blue, .70);
$blue60: rgba($brand-blue, .60);
$blue50: rgba($brand-blue, .50);
$blue40: rgba($brand-blue, .40);
$blue30: rgba($brand-blue, .30);
$blue20: rgba($brand-blue, .20);
$blue10: rgba($brand-blue, .10);
$blue5: rgba($brand-blue, .05);

$yellow100: $yellow;
$yellow80: rgba($yellow, .80);
$yellow90: rgba($yellow, .90);
$yellow70: rgba($yellow, .70);
$yellow60: rgba($yellow, .60);
$yellow50: rgba($yellow, .50);
$yellow40: rgba($yellow, .40);
$yellow30: rgba($yellow, .30);
$yellow20: rgba($yellow, .20);
$yellow10: rgba($yellow, .10);
$yellow5: rgba($yellow, .05);

$green100: $green;
$green80: rgba($green, .80);
$green90: rgba($green, .90);
$green70: rgba($green, .70);
$green60: rgba($green, .60);
$green50: rgba($green, .50);
$green40: rgba($green, .40);
$green30: rgba($green, .30);
$green20: rgba($green, .20);
$green10: rgba($green, .10);
$green5: rgba($green, .05);

$grey100: $grey;
$grey80: rgba($grey, .80);
$grey90: rgba($grey, .90);
$grey70: rgba($grey, .70);
$grey60: rgba($grey, .60);
$grey50: rgba($grey, .50);
$grey40: rgba($grey, .40);
$grey30: rgba($grey, .30);
$grey20: rgba($grey, .20);
$grey10: rgba($grey, .10);
$grey5: rgba($grey, .05);

$red100: $red;
$red80: rgba($red, .80);
$red90: rgba($red, .90);
$red70: rgba($red, .70);
$red60: rgba($red, .60);
$red50: rgba($red, .50);
$red40: rgba($red, .40);
$red30: rgba($red, .30);
$red20: rgba($red, .20);
$red10: rgba($red, .10);
$red5: rgba($red, .05);

$text-primary: $grey70;
$text-h1: $grey70;
$text-h2: $grey30;

$primary: $blue100;
$focus: $orange;


$box-shadow-color: $grey30;

$border-color: $grey30;
$border-radius: 3px;
