.text {
  color: #4D576D;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(.compact) {
    font-weight: bold;
  }
}
