.functionsHeader {
  font-weight: 600;
  color: #B2B7C0;
  font-size: 12px;
  padding-bottom: 10px;
}


.functionButtons {
  gap: 10px;

  > div {
    width: 52px;
    height: 52px;
    background-color: #F5F6F7;
    border-radius: 3px;
    padding: 10px;
    border: 1px solid #ECEEF3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.totalContainer {
  border: 1px solid #ECEEF3;
  border-radius: 6px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;

  > div {
    padding: 10px 20px;
  }
}

.totalTime {
  color: #0D60F8;

  .label {
    font-size: 12px;
  }

  .value {
    font-size: 18px;
    font-weight: 800;
  }
}

.totalBillable {
  background-color: #0D60F8;
  color: white;

  .label {
    font-size: 12px;
    color: #BED5FF;
  }

  .value {
    font-size: 18px;
    font-weight: 800;
  }
}

.grid {
  display: grid;
  flex: 1;
  gap: 20px;
  width: calc(100% - 20px);

  grid-template-columns: repeat(2, calc(50% - 20px));

  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.displayGrid {
  width: 100%;
  display: grid;
  gap: 20px;
}

.gridItem {
  display: flex;
  flex: 1;
  gap: 20px;
  overflow: hidden;
}

.tagContainer {
  display: flex;
  gap: 10px;
  overflow: hidden;
}

.tag {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  border: 1px solid #E4E8F0;
  padding: 4px 10px;
  min-width: fit-content;

  background-color: #F5F6F7;

  &.active {
    background-color: rgba(13, 96, 248, 0.1);
    border-color: #0D60F8;
    color: #0D60F8;
    font-weight: 800;
  }
}

.dropDownContent {
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  height: max-content;

  &::-webkit-scrollbar {
    background-color: darken(#fff, 5%);
    width: 2px;
    border-radius: 6px;
  }

  padding: 15px;
}

.dropdownHeader {
  font-weight: 600;
  color: #B2B7C0;
  font-size: 12px;
}

.dropDownItem {
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  text-align: start;
  cursor: pointer;
  border: 1px solid #E4E8F0;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    border-color: #0D60F8;
    color: #0D60F8;

    > * > * {
      fill: #0D60F8;
    }
  }
}

.formatContainer {
  color: #0D60F8;
  font-weight: bold;
}