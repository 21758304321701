.menu {
  position: relative;
  height: 100vh;

  &.topBanner {
    height: calc(100vh - 34px)
  }


  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0d60f8+0,06245b+89 */
  background: #0d60f8; /* Old browsers */
  background: -moz-linear-gradient(top,  #0d60f8 0%, #06245b 89%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #0d60f8 0%,#06245b 89%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #0d60f8 0%,#06245b 89%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d60f8', endColorstr='#06245b',GradientType=0 ); /* IE6-9 */

  box-shadow: 1px 0 25px rgba(0,0,0, .4);

  width: clamp(195px, 15vw, 350px);

  transition: all .4s ease-in-out;

  > * {
    overflow: hidden;
  }
}

.menuBackground {

  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  display: grid;
  grid-template-rows: min-content auto min-content;
  overflow: visible;
}

.logo {
  padding: 30px 0 30px 0;
}

.logoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.separator {
  width: 80%;
  border-bottom: 1px solid rgba(3, 36, 91, .20);
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar {
      width: 7px;
      height: 0;
    }
  }
}

.menuItemLabel {
  padding-left: 25px;
  color: rgba(255,255,255,.3);
}

.footer {
  color: white;
  margin-bottom: 30px;
}


.versionTag {
  margin-top: 24px;
  padding: 3px 10px;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.upgradeButton {
  padding: 3px 10px;
}
