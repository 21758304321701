.tag {
  padding: 10px 15px;
  width: 100%;
  text-align: start;

  &:hover {
    background-color: #EBF2FF;
  }

  &.selected {
    background-color: #EBF2FF;
    font-weight: 600;
  }
}

.content {
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  height: max-content;

  &::-webkit-scrollbar {
    background-color: darken(#fff, 5%);
    width: 2px;
    border-radius: 6px;
  }
}
