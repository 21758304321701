@import 'src/Styles/Constants';


.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.loginContainer {
  flex: 1;
  position: relative;
  display: flex;
  padding: 100px 0;

  box-shadow: 10px 0 20px rgba(0,0,0,0.16);

  > .inner {
    width: 100%;
    margin:auto;
    padding: 20px clamp(30px, 10vw, 100px) 0 clamp(30px, 10vw, 100px);

    .preHeader {
      font-size: 1.5em;
      color: #05245D;
      line-height: .8;
    }

    .header {
      font-size: 3.5em;
      color: #05245D;
      font-weight: 800;
    }

    .form {
      padding-top: 20px;
    }
  }


  .errorMessage {
    color: $red;
  }
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .mockupImg {
    width: 90%;
  }
}

.link {
  padding-top: 10px;
  color: #9AAAC9;

  > a {
    color: #9AAAC9;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1100px) {
  .container {
    grid-template-columns: 1fr;
  }

  .background {
    display: none;
  }
}
