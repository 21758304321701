.box {
  opacity: .98;
  background-color: white;

  margin-bottom: 15px;
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.paddingBottom {
  padding-bottom: 10px;
}

.durationContainer {
  height: 35px;
  background-color: #E0E9F9;
  border-radius: 3px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.day {
  font-size: 1em;
  color: #999FAB;
  font-weight: 600;
}

.date {
  font-size: 1.5em;
  font-weight: 700;
  color: #4D576D;
}

.registrationContainer {
  padding: 0;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}
