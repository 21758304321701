.column {
  font-size: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4D576D;

  &.blank {
    color: lighten(#4D576D, 45%);
  }

}
.separator {
  padding: 5px 0;

  border-bottom: 1;
}
