$line-height: 1.1;
$separatorColor: rgba(178, 183, 192, 0.3);

.container {
  border: 1px solid #B2B7C0;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;

  &.open {
    border-color: #0D60F8;
  }
}

.header {
  padding: 10px 0;
  display: grid;
  grid-row: 1;
  overflow: scroll;
  grid-template-columns: auto min-content clamp(250px, 20%, 400px);

  > div {
    padding: 0 20px;
    white-space: nowrap;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:not(:last-child) {
    border-right: 2px solid $separatorColor;
  }
}

.label {
  font-size: 14px;
  color: rgba(77, 87, 109, 0.6);
  line-height: $line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.groupLabel {
  font-size: 16px;
  font-weight: 800;
  line-height: $line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p {
  font-size: 16px;
  color: #4D576D;
  line-height: $line-height;
}

.table {
  display: grid;
  overflow: scroll;
  grid-template-columns: min-content min-content min-content min-content auto min-content min-content min-content;
  height: 0;

  transition: all 0.3s ease-in-out;

  &.open {
    height: fit-content;
  }
}

.tr {
  display: contents;

  &:nth-child(2n + 1) > div {
    background-color: rgba(240, 240, 240, 0.35);
  }

  &:first-child > div {
    background-color: rgb(240, 240, 240);
    text-transform: uppercase;
  }
}

.th {
  padding: 0 15px;
  background-color: #F1F2F4;
  color: #B2B7C0;
  white-space: nowrap;
}

$tdHeight: 40px;

.td {
  padding: 0 15px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  height: $tdHeight;
  line-height: $tdHeight;

  &:not(:nth-child(8n)):not(:first-child):after {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    display: block;
    width: 2px;
    height: 60%;
    background-color: $separatorColor;
  }
}
