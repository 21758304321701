@import 'src/Styles/Constants';

.checkboxRoot {

  transition: all 100ms ease-in-out;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #E4E8F0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.checked {
    border-color: $brand-blue;
  }
}

.checkboxIndicator {
  display: flex;
  justify-content: center;
  align-content: center;
  color: $brand-blue;
}

.label {
  padding-left: 10px;
  padding-bottom: 0;
}
