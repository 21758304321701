@import 'src/Styles/Constants';

.button {
    -webkit-border-radius: $border-radius;
    -moz-border-radius: $border-radius;
    border-radius: $border-radius;
    font-size: 1em;
    text-align: center;
    padding: .7em 1.5em;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    border: 0;
    transition: background-color .3s ease-out, box-shadow .3s ease-out;

    &.primary {
        font-weight: 600;
        color: white;
        background-color: $brand-blue;

        &:hover {
            background-color: darken($brand-blue, 5%);
        }
    }

    &.default {
        background: #E4E8F0;

        &:hover {
            background-color: darken(#E4E8F0, 5%);
        }
    }

    &.danger {
        font-weight: 600;
        background: red;
        color: white;

        &:hover {
            background-color: darken(red, 10%);
        }
    }

    &.block {
        width: 100%;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.85;
    }
}
