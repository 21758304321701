.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  transition: all 0.15s ease-in-out;
  pointer-events: none;
  opacity: 0;

  &.enterDone {
    opacity: 1;
    pointer-events: visible;

    > .content {
      transform: translateY(10vh);
    }
  }

  &.exit {
    opacity: 0;
  }

  > .content {
    margin: 10px;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;

    background-color: #fff;
    transition: all 0.15s ease-in-out;
    transform: none;
    border-radius: 3px;
  }
}

.header {
  padding: 30px 30px 0 30px;

  > span {
    font-weight: bold;
    font-size: 20px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  padding: 20px 30px 30px 30px;
}
