@import 'src/Styles/Constants';

.button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  border-width: 2px;
  border-style: solid;
  border-radius: 4px;

  &.block {
    width: 100%;
  }

  > .children {
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  > .icon {

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 3px 3px 0;
    padding: 5px 10px 5px 10px;
  }

  &.default {
    border-color: #E4E8F0;

    &:hover {
      background-color: darken(white, 5%);

      > .icon {
        background-color: darken($brand-blue, 5%);
      }
    }

    > .children {
      color: #4D576D;
    }

    > .icon {
      border-left: 1px solid #E4E8F0;
      background-color: $brand-blue;

    }
  }

  &.blue {
    background-color: $brand-blue;
    border-color: #0C59E8;
    color: white;

    &:hover {
      background-color: darken($brand-blue, 5%);

      > .icon {
        background-color: darken(white, 5%);
      }
    }

    > .children {
      color: white;
    }

    > .icon {
      background-color: white;
      border-color: $brand-blue;

      > svg {
        fill: $brand-blue;
      }
    }
  }

  &.red {
    border-color: #F74425;

    &:hover {
      background-color: darken(white, 5%);

      > .icon {
        background-color: darken(#F74425, 5%);
      }
    }

    > .children {
      color: #F74425;
    }

    > .icon {
      background-color: #F74425;
    }
  }
}
