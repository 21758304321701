.container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
}

.step {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F9FF;
  color: #ADB7CA;

  &.active {
    background-color: #0D60F8;
    font-weight: bold;
    color: white;
  }
}
