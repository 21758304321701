@import 'src/Styles/Constants';


hr {
  margin: 20px 0 10px 0;
}

.alert {
  font-weight: 700;
  background-color: $red;
  color: white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  opacity: .2;
  pointer-events: none;
}

.box {
  border: 1px solid #B2B7C0;
  border-radius: 5px;
  padding: 40px;
  height: 100%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.hasPackageRibbon {
  width: 250px;
  padding: 10px;
  text-align: center;
  color: white;
  position: absolute;
  top: 40px;
  right: -50px;
  transform: rotate(45deg);

  &.free {
    background-color: $brand-blue;
  }

  &.premium {
    background-color: $action-blue;
  }
}

.priceCol {
  width: 50%;
  color: #707070;
}

.totalPrice {
  font-size: 3.5em;
  line-height: 1em;
  padding-bottom: 20px;
  font-weight: 700;

  &.free {
      color: $brand-blue;
  }

  &.premium {
    color: $action-blue;
  }
}

.unitPrice {
  font-size: 2em;
  line-height: 1.8em;
  padding-bottom: 20px;
  color: #707070;
}

.packageDescription {
  color: #707070;
  margin-bottom: 30px;

  & > div {
    max-width: 70%;
  }
}

.packageName {
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 10px;

  &.free {
    color: $brand-blue;
  }

  &.premium {
    color: $action-blue;
  }
}

.featureTitle {
  font-size: 1.1em;
  font-weight: 600;

  &.free {
    color: $brand-blue;
  }

  &.premium {
    color: $action-blue;
  }
}

.discountBox {
  white-space: nowrap;
  border: 2px solid $action-blue;
  border-radius: 5px;
  padding: 0 5px;
  color: $action-blue;
  font-weight: 600;
  margin-left: 5px;
}

.userCountBox {
  font-weight: 700;
  background-color: #F2F7FF;
  border: 1px solid rgba(178, 183, 192, 0.28);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 10px 0;
  border-radius: 5px;
}

.switch {
  margin-bottom: 20px;
  width: 200px;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-radius: 40px;
  overflow: hidden;
  border: 1px solid #C3D6ED;
  background-color: #F2F7FF;

  & > .item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    padding: 0 20px;
    border-radius: 40px;
    width: 50%;
    border: 1px solid transparent;
    color: rgba(77, 87, 109, 0.5);

    transition: all 0.2s ease-in-out;

    &.active {
      border: 1px solid #C3D6ED;
      color: white;
      background-color: $brand-blue;
    }
  }
}
