.createItem {
  color: #4D576D;
  padding: 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #02112f0d;
  }
}
