.container {
  overflow: hidden;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  overflow: scroll;
  z-index: 1;
  position: relative;
  margin-bottom: -2px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabContentContainer {
  overflow: auto;
  white-space: nowrap;

  background-color: white;
  border: 1px solid #C5CEE2;
  border-radius: 3px;
}

.tabContentInnerContainer {
  padding: 20px;
}
