.container {
  position: relative;
  background-color: #FAFCFF;
  border: 1px solid #E4E8F0;
  border-radius: 3px;
  min-height: 3.5rem;
  display: flex;
  align-items: flex-end;
  width: 100%;

  &.active {
    border: 1px solid #0D60F8;
  }

  &.error {
    border: 1px solid #EF2222;
  }
}

.label {
  font-weight: 500;
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 15px;
  transition: all 150ms ease-in;
  -webkit-appearance: none !important;
  pointer-events: none;
  color: #9AAAC9;
}

.fieldActive {
  transform: translateY(-8px);
  font-size: .8em;
}

.floatingLabel {
  -webkit-appearance: none !important;
}

textarea.input {
  margin-top: 30px;
  line-height: 1rem;
  min-height: 6em;
}

.input {
  font-family: krub, sans-serif;

  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: #05245D;
  font-size: 0.9rem;
  font-weight: 500;

  display: inline-block;
  outline: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;

  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
}

.input::placeholder {
  color: transparent;
}

.suffix {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  font-weight: 600;
  color: #2F3A57;
}
