.box {
  background-color: white;
  height: 250px;
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.paddingBottom {
  padding-bottom: 10px;
}

.durationBox {
  border-radius: 3px;
  background-color: #0D60F8;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 20px;
}

.day {
  font-size: 1em;
  color: #999FAB;
  font-weight: 600;
}

.date {
  font-size: 1.5em;
  font-weight: 700;
  color: #4D576D;
}

.registrationContainer {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.durationContainer {
  height: 35px;
  background-color: #E0E9F9;
  border-radius: 3px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
