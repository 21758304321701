.container {
  width: 100%;
  margin-right: 20px;
  padding-right: 20px;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contentContainer {
  width: 100%;
  flex: 1;
}
