@import 'src/Styles/Constants';

.input {
  font-family: krub, sans-serif;
  outline: 0;
  padding: 1em 0.85em;
  line-height: 3em;
  height: 3em;
  font-size: 0.9rem;

  display: inline-block;

  border-radius: $border-radius;
  border: 1px solid $grey40;

  &::placeholder {
    color: $grey30;
  }

  &:hover {
    border-color: $blue60;
  }

  &.full-width {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid $red;
  }
}


.ant-select {
  &.error {
    > div {
      border: 1px solid $red!important;
    }
  }
}
