.contentContainer {
  display: grid;
  grid-template-columns: 1fr clamp(200px, 20vw, 600px);
  grid-gap: 100px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(250px, 20%, 350px), 1fr));
  grid-gap: 20px;
}
