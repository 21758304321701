@import 'src/Styles/Constants';

.container {
  display: grid;
  grid-template-columns: 67% 33%;
  min-height: 42px;

  padding: 5px 0;

  border-bottom: 1px solid #e6e6e6;

}

.customerText {
  font-size: .8em;
  color: #B2B7C0;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.blank {
    color: lighten(#B2B7C0, 15%);
  }
}

.descriptionText {
  font-size: 1.1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4D576D;

  &.blank {
    color: lighten(#4D576D, 40%);
  }
}

.textColumn {
  width: 100%;
}

.durationColumn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
