@import 'src/Styles/Constants';
@import 'src/Styles/input';
@import 'src/Styles/utils';

html {
  font-size: 100%;
}

body {
  height: 100%;
  font-family: 'krub', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $text-primary;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

*, :after, :before {
  box-sizing: border-box!important;
  font-family: 'krub', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.box {
  border-radius: $border-radius;
  border: 1px solid #B2B7C0;
  transition: all  0.2s ease-in-out;

  &:hover:not(.dont-hover), &.active {
    border-color: #0D60F8;
    background-color: #FAFCFF;
  }

  &.white {
    background-color: white;
  }
}

.clickable {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.padding30 {
  padding: 30px;
}

.padding15 {
  padding: 15px;
}

hr, .hr {
  color: rgba(0,0,0,0.3);
  border-color: #F2F2F2;
  border-top: 1px;
  border-radius: 3px;
}

/* width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
  border-radius: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99a0ac;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-modal-mask {
  background-color: rgba(0,0,0,0.90)!important;
}

// Notifications
.notification {
  border-radius: 5px!important;

  border-style: solid;
  border-width: 0 0 0 10px;
  box-shadow: 0 0 60px rgba(28, 59, 142, .15);

  &-success{
    border-color: #34DC61!important;
  }

  &-error{
    border-color: #E24C4B!important;
  }
}

.ant-notification-notice-message {
  font-weight: bold;
  text-transform: uppercase;
}

.ant-select-item-empty {
  padding: 0!important;
}

// react-big-calendar style overrides
$out-of-range-color: transparent;
$out-of-range-bg-color: transparent;

$calendar-border: transparent;
$cell-border: #EEE;

$time-selection-bg-color: #0D60F8;

$event-bg: white;
$event-border: 1px solid black;
$event-outline: transparent;
$event-color: black;
$event-border-radius: $border-radius;
$event-zindex: 4;

$current-time-color: #0D60F8;
$today-highlight-bg: transparent;

@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.rbc-timeops-running-event, .rbc-slot-selection {
  background-color: white;
  color: black
}

.rbc-event, .rbc-slot-selection {
  border: 1px solid #B2B7C0;
  border-radius: $border-radius!important;
  transition: all 0.2s ease-in-out!important;

  &:hover:not(.rbc-slot-selection) {
    border-color: #6D9FFB;
    background-color: #FAFCFF;
  }

  &.rbc-selected {
    border-color: #6D9FFB;
    background-color: #FAFCFF!important;
  }
}

.rbc-event-label, .rbc-slot-selection > span {
  display: flex;
  width: 100%;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-weight: bold;
}

.rbc-time-slot {

}

.rbc-time-gutter > .rbc-timeslot-group {
  border-color: transparent;

  &:first-child {
    visibility: hidden;
  }

  > .rbc-time-slot > span {
    top: -11px; // Centers the time string on the horizontal line
    color: rgba(102, 102, 102, .7);
    position: relative;
    font-weight: bold;
  }
}

.rbc-allday-cell {
  display: none;
}

.rbc-timeslot-group {
  height: 60px;
}

.rbc-current-time-indicator {
  height: 3px;
  border-radius: $border-radius!important;
  border-left: none;
}
