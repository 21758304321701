.container {
  height: 100%;
  padding: 2px;
}

.customerText {
  font-size: .8em;
  color: #B2B7C0;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.descriptionText {
  font-size: 1.1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4D576D;
}
