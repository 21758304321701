.container {
  border-radius: 3px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.09);
  padding: 10px 40px 11px;
  background-color: white;
  position: relative;
  z-index: 1;

  border: solid 1px #E9E9E9;

  margin: clamp(10px, 2vh, 30px) clamp(10px, 2vw, 50px) 0 clamp(10px, 2vw, 50px);
}

.projectSelect {
  width: 100%;
  font-size: 1.2em;
  color: black;

  > * {
    padding: 0 !important;


    > * {
      padding: 0!important;
      left: 0!important;

      color: #757575!important;
      &::placeholder {
        color: black!important;
      }
      > * {
        padding: 0!important;

        &::placeholder {
          color: black!important;
        }
      }
    }
  }

}

.inputLabel {
  color: #B2B7C0;
  font-weight: bold;
  font-size: .7em;
  width: 100%;
}

.input {
  box-shadow: none;
  font-size: 1.2em;
  border: none;
  width: 100%;
  padding: 0px;
  outline: none;
  color: black;

  &.selected::placeholder {
    color: black;
  }

  &.selected:placeholder-shown {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    box-shadow: none;
  }
}

.iconCircle {
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 200em;
  display: flex;
  justify-content: center;
  align-items: center;


}

.stopIcon {
  border-radius: 1px;
  background-color: white;
}
