@import 'src/Styles/Constants';

.subMenuLink {
  display: flex;
  width: 100%;
  padding-left: 1.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: flex-start;
}

.subMenuLinkIcon {
  height: 25px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subMenuLinkContent {
  font-size: 1em;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    font-weight: 600;
  }
}
