.menuLink {
  margin: 10px 0;
  height: 3.5rem;

  display: flex;
  align-items: center;

  color: white;
  font-size: 1.2em;
  line-height: 2.7rem;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition-property: fill, font-weight, color, background-color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  > .menuLinkIcon > svg{
    fill: white;
  }

  &:hover {
    > * {
      font-weight: 600;
    }
  }

  &.active {
    background-color: white;
    box-shadow: 3px 0 6px rgba(0,0,0, .1);
    color: #13397F;

    > * {
      font-weight: 600;
    }

    > .menuLinkIcon > * {
      fill: #13397F;
    }
  }
}


.menuLinkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 5px;
  width: 45px;

  > svg {
    max-height: 55%;
    fill: #9F9F9F;
  }
}


.submenu {
  max-height: 0;

  transition: max-height .2s ease-in-out;
  overflow: hidden;

  &.open {
    max-height: 500px;
  }
}
