.paper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 794px;
  min-height: 1057px;
  padding: 50px 45px 30px 45px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 10px 10px rgba(0,0,0, .1);
  position: relative;
}

.dateAndCommentContainer {
  min-height: 200px;
}

.dateContainer {
  margin: 30px 0
}

.commentContainer {
  margin: 0 0 20px 0
}

.totalContainer {
  margin-top: 20px;
  width: 300px;
}
