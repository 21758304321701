$blockSize: 800px;
$blockSizePlus1 : calc(#{$blockSize} + 1px);

$separatorPosition: 20px;

$borderRadius: 3px;
$defaultBorderColor: #B2B7C0;
$defaultBorderWidth: 1px;
$columnBorder: 1px solid $defaultBorderColor;

.container {
  width: 100%;
  display: grid;

  grid-auto-rows: 1fr;

  @media (max-width: $blockSize) {
    grid-template-columns: 1fr!important;
  }
}

.row {
  display: contents;

  &:hover > .column {
    border-color: #0D60F8;
    background-color: #FAFCFF;
  }
}

.loadingContainer {
  background-color: white;
  border: $defaultBorderWidth solid $defaultBorderColor;
  border-radius: $borderRadius;
  padding: 7px 20px;

  &:not(.compact) {
    padding: 10px 20px;
  }
}

.column {
  background-color: white;
  width: 100%;
  display: flex;
  overflow-x: hidden;

  border-color: $defaultBorderColor;

  @media (min-width: $blockSizePlus1) {
    margin-bottom: 10px;

    padding: 10px 0;

    &.compact {
      padding: 7px 0;
    }
    border-style: solid ;
    border-width: 0;
    border-top-width: 1px;
    border-bottom-width: $defaultBorderWidth;

    &:first-child {
      padding-left: clamp(8px, 1vw, 20px);
      padding-right: clamp(8px, 1vw, 20px);
      border-left-width: $defaultBorderWidth;
      border-radius: $borderRadius 0 0 $borderRadius;
    }

    &:last-child {
      border-right-width: $defaultBorderWidth;
      border-radius: 0 $borderRadius $borderRadius 0;
    }
  }

  @media (max-width: $blockSize) {
    border-right: $columnBorder;
    border-left: $columnBorder;

    padding: 5px 20px;

    &:first-child {
      padding-top: 10px;
      border-top: $columnBorder;
      border-radius: $borderRadius $borderRadius 0 0;
    }

    &:last-child {
      border-bottom: $columnBorder;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-radius: 0 0 $borderRadius $borderRadius;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  fill: #B2B7C0;
}


.separator {
  height: 100%;

  @media (min-width: $blockSizePlus1) {
    padding: 0 clamp(8px, 1vw, 20px);
    border-left: 1px solid #B2B7C0;
  }

  @media (max-width: $blockSize) {
    padding-top: 10px;
    border-top: 1px solid #B2B7C0;
  }

}

.empty {
  margin: 30px;
}
