.content {
  border-radius: 4px;
  padding: 20px;
  background-color: white;
  border: 1px solid #E4E8F0;
  z-index: 10900;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  animation-name: slideDownAndFade;
  box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.15);

}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}