@import 'src/Styles/Constants.scss';

/* reset */
button {
  all: unset;
}

.tooltipContent {
  border-radius: 3px;
  border: 1px solid #E4E8F0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.16);
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $brand-blue;
    border-radius: 3px 3px 0 0;
  }
}
.tooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.tooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.tooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.tooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.tooltipArrow {
  fill: white;
}

.button {
  font-family: inherit;
  background-color: transparent;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
