$transition: all 0.3s ease-in-out;

.container {
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;

  &.hasValue::after {
    width: 100%;
  }

  &.active:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 4px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #0D60F8;
  }

  &.active {
    padding-right: 10px;
    padding-left: 10px;

    transition: $transition;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #C5CEE2;
    border-bottom: 1px solid transparent;
  }
}

.contentContainer {
  display: flex;
  align-items: center;
  position: relative;
  height: 4.5em;
}

.labelContainer {

  overflow: hidden;
  transition: $transition;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 0;

  text-overflow: ellipsis;

  &.enterDone {
    width: 180px;
    max-width: fit-content;
    padding: 0 10px 0 10px;
  }

  &.exit {
    width: 0;
    padding: 0;
  }
}

.label {
  font-size: 14px;
  color: #0D60F8;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subLabel {
  color: #0D60F8;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  transition: $transition;
}
