$transition: all 150ms ease-in-out;

.trigger {
  position: relative;
  background-color: #FAFCFF;
  border: 1px solid #E4E8F0;
  border-radius: 3px;
  min-height: 3.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > div:not(.clear) {
    width: 45%;
    position: relative;
  }

  &.active {
    border: 1px solid #0D60F8;
    border-radius: 3px 3px 0 0;
  }

  &.error {
    border: 1px solid #EF2222;
  }
}

.label {
  font-weight: 500;
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 15px;
  transition: $transition;
  -webkit-appearance: none !important;
  pointer-events: none;
  color: #9AAAC9;
}

.fieldActive {
  transform: translateY(-8px);
  font-size: 10px;
}

.floatingLabel {
  -webkit-appearance: none !important;
}

.value {
  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 0 15px;
  color: #05245D;
  font-size: 0.9rem;
  font-weight: 500;

  display: inline-block;
  outline: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;
  flex: 1;
}

.input {
  line-height: 2.7rem;
  min-height: 2.7rem;

  padding: 10px 15px 0 15px;
  color: #05245D;
  font-size: 0.9rem;
  font-weight: 500;

  display: inline-block;
  outline: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;
  flex: 1;
}

.container {
  position: relative;

  width: 100%;
  cursor: pointer;
}


.valueContainer {
  padding-right: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clear {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_cell {
  > * {
    color: #4D576D;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.caption_label {
  text-transform: uppercase;
  font-weight: 600;
  color: #4D576D;
}

.day {
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover:not(.daySelected) {
    color: white;
    background-color: rgba(33, 109, 248, 0.8);
    border-radius: 40px;

    > * {
      font-weight: 600;
    }
  }

}

.button {
  outline: none;
  border: none;
  background-color: transparent;
}

.daySelected {
  background-color: #216DF8;
  color: white;
  border-radius: 40px;

  &.dayRangeStart {
    border-radius: 40px 0 0 40px;
  }

  &.dayRangeEnd {
    border-radius: 0 40px 40px 0;
  }

  &.dayRangeEnd.dayRangeStart {
    border-radius: 40px;
  }

  &.dayRangeMiddle {
    border-radius: 0;
  }

  > * {
    font-weight: 600;
  }
}

.months {
  display: flex;;

  > .month:not(:last-child) {
    padding-right: 13px;
    border-right: 2px solid rgba(178, 183, 192, 0.5);
  }

  > .month:not(:first-child) {
    padding-left: 13px;
  }
}
